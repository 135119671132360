const Whatsapp = ()=>{
  return(    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 636.32 639.38"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path className="cls-1" d="M543.61,92.92A314.79,314.79,0,0,0,319.49,0C144.85,0,2.73,142.12,2.66,316.8A316.18,316.18,0,0,0,45,475.2L0,639.38l168-44.06a316.59,316.59,0,0,0,151.4,38.56h.13c174.62,0,316.76-142.13,316.83-316.82A314.9,314.9,0,0,0,543.61,92.92ZM319.49,580.37h-.11a263,263,0,0,1-134-36.7L175.74,538,76.07,564.1l26.6-97.17-6.26-10A262.74,262.74,0,0,1,56.15,316.82C56.21,171.63,174.34,53.51,319.59,53.51A263.37,263.37,0,0,1,582.82,317C582.76,462.24,464.64,580.37,319.49,580.37ZM463.93,383.15c-7.91-4-46.83-23.11-54.09-25.76s-12.54-3.95-17.81,4-20.45,25.75-25.07,31-9.23,5.95-17.15,2-33.42-12.33-63.66-39.3c-23.54-21-39.42-46.91-44-54.83s0-11.81,3.47-16.16c8.57-10.64,17.15-21.8,19.79-27.08s1.32-9.91-.66-13.87-17.81-42.93-24.41-58.78c-6.43-15.43-13-13.35-17.81-13.59-4.61-.23-9.89-.28-15.17-.28s-13.86,2-21.12,9.91-27.7,27.08-27.7,66,28.37,76.61,32.33,81.9,55.82,85.24,135.23,119.53a454.73,454.73,0,0,0,45.13,16.68c19,6,36.22,5.17,49.86,3.13,15.21-2.27,46.84-19.15,53.44-37.64s6.59-34.34,4.61-37.65S471.85,387.11,463.93,383.15Z"/></g></g></svg>
  )
}
const Instragram = ()=>{
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438.536 438.536" className="svg-icon" id="" fill="currentColor" title=""> <path d="M421.981,16.562C410.941,5.519,397.711,0,382.298,0H56.248C40.83,0,27.604,5.521,16.561,16.562C5.52,27.6,0.001,40.828,0.001,56.243V382.29c0,15.413,5.518,28.644,16.56,39.683c11.043,11.04,24.272,16.563,39.687,16.563h326.046c15.41,0,28.644-5.523,39.684-16.563c11.043-11.039,16.557-24.27,16.557-39.683V56.243C438.534,40.825,433.021,27.604,421.981,16.562z M157.462,158.025c17.224-16.652,37.924-24.982,62.097-24.982c24.36,0,45.153,8.33,62.381,24.982c17.228,16.655,25.837,36.785,25.837,60.386c0,23.598-8.609,43.729-25.837,60.379c-17.228,16.659-38.014,24.988-62.381,24.988c-24.172,0-44.87-8.336-62.097-24.988c-17.228-16.652-25.841-36.781-25.841-60.379C131.621,194.81,140.234,174.681,157.462,158.025z M388.865,370.589c0,4.945-1.718,9.083-5.141,12.416c-3.433,3.33-7.519,4.996-12.282,4.996h-305.2c-4.948,0-9.091-1.666-12.419-4.996c-3.333-3.326-4.998-7.471-4.998-12.416V185.575H89.08c-3.805,11.993-5.708,24.462-5.708,37.402c0,36.553,13.322,67.715,39.969,93.511c26.65,25.786,58.721,38.685,96.217,38.685c24.744,0,47.583-5.903,68.527-17.703c20.937-11.807,37.486-27.839,49.676-48.112c12.183-20.272,18.274-42.4,18.274-66.38c0-12.94-1.91-25.406-5.715-37.402h38.547v185.014H388.865z M388.865,115.626c0,5.52-1.903,10.184-5.716,13.99c-3.805,3.809-8.466,5.711-13.989,5.711h-49.676c-5.517,0-10.185-1.903-13.99-5.711c-3.806-3.806-5.708-8.47-5.708-13.99V68.522c0-5.33,1.902-9.945,5.708-13.848c3.806-3.901,8.474-5.854,13.99-5.854h49.676c5.523,0,10.185,1.952,13.989,5.854c3.812,3.903,5.716,8.518,5.716,13.848V115.626z"></path> </svg>
  )
}
const Twitter = ()=>{
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="svg-icon" id="" fill="currentColor" title=""><path d="M14.3554 4.76542C14.3655 4.90649 14.3655 5.04758 14.3655 5.18865C14.3655 9.49131 11.066 14.449 5.03555 14.449C3.17767 14.449 1.45179 13.9149 0 12.9879C0.26397 13.0181 0.517752 13.0282 0.791878 13.0282C2.32485 13.0282 3.73605 12.5143 4.86296 11.6376C3.42133 11.6074 2.2132 10.6703 1.79694 9.38048C2.00001 9.4107 2.20304 9.43086 2.41626 9.43086C2.71067 9.43086 3.00511 9.39053 3.2792 9.32003C1.77666 9.01771 0.649721 7.70778 0.649721 6.12576V6.08547C1.08625 6.32731 1.59391 6.47845 2.13195 6.49858C1.24869 5.91413 0.670034 4.91657 0.670034 3.78799C0.670034 3.18341 0.832441 2.6292 1.11672 2.14552C2.73095 4.12052 5.15736 5.4103 7.87816 5.55139C7.82741 5.30955 7.79694 5.05766 7.79694 4.80575C7.79694 3.0121 9.25888 1.55102 11.0761 1.55102C12.0203 1.55102 12.8731 1.94401 13.4721 2.57883C14.2132 2.43776 14.9238 2.16568 15.5533 1.79286C15.3096 2.54862 14.7919 3.18344 14.1117 3.58647C14.7716 3.51597 15.4112 3.33455 16 3.08267C15.5534 3.72754 14.995 4.30188 14.3554 4.76542V4.76542Z"></path></svg>
  )
}
const Mail = ()=>{
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2881 2292 14 14" className="svg-icon" id="" fill="currentColor" title=""><g className="b" transform="translate(-3025 -1384)"><g transform="translate(144 3676)"><path className="a" d="M1.344,4.891q.266.187,1.6,1.113T4.992,7.43l.332.238q.254.184.422.3t.406.254a2.357,2.357,0,0,0,.449.211,1.238,1.238,0,0,0,.391.07h.016a1.239,1.239,0,0,0,.391-.07,2.359,2.359,0,0,0,.449-.211q.238-.141.406-.254t.422-.3q.254-.184.332-.238l3.656-2.539a3.711,3.711,0,0,0,.953-.961A2.063,2.063,0,0,0,14,2.75,1.261,1.261,0,0,0,12.75,1.5H1.25a1.116,1.116,0,0,0-.926.406A1.577,1.577,0,0,0,0,2.922,1.825,1.825,0,0,0,.43,3.988,3.878,3.878,0,0,0,1.344,4.891Z"></path><path className="a" d="M13.219,5.727q-2.562,1.734-3.89,2.7-.445.328-.723.512a4.46,4.46,0,0,1-.738.375,2.248,2.248,0,0,1-.859.191H6.992a2.249,2.249,0,0,1-.859-.191,4.462,4.462,0,0,1-.738-.375q-.277-.183-.723-.512Q3.617,7.649.789,5.727A4.138,4.138,0,0,1,0,5.047v6.2a1.2,1.2,0,0,0,.367.883,1.2,1.2,0,0,0,.883.367h11.5A1.254,1.254,0,0,0,14,11.25v-6.2A4.308,4.308,0,0,1,13.219,5.727Z"></path></g></g> </svg>
  )
}
const Facebook = ()=>{
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.98 23.93"><defs></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path className="cls-1" d="M12.13,13.64l.68-4.39H8.6V6.4A2.19,2.19,0,0,1,11.07,4H13V.3A22.92,22.92,0,0,0,9.59,0C6.12,0,3.85,2.1,3.85,5.9V9.25H0v4.39H3.85V23.93H8.6V13.64Z"/></g></g></svg>
  )
}

export {
  Whatsapp,
  Instragram,
  Twitter,
  Mail,
  Facebook
}